import { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one, check_subdomain } from '../lib/server_helper';
import do_login, { loginAtom, do_login_sms_code } from '../lib/auth';
import { currentProjectAtom } from '../lib/project';

import {
  Box,
  Checkbox,
  Grid
} from '@mui/material';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import ItsmeLoginButton from '../images/itsme.svg';

import {
  Link,
  StyledButton,
  StyledTextField,
  Typography
} from '../components/interface';

import { User } from '../models/User';

type LoginProps = {
  embed_token?: string;
};

export const Login: FunctionComponent<LoginProps> = ({embed_token}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);
  const nonmobile = useMediaQuery('(min-width:600px)');

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isCheckedError, setIsCheckedError] = useState<boolean>(false);
  const [notice, setNotice] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [smsCode, setSmsCode] = useState<string>('');
  const [showCodeField, setShowCodeField] = useState<boolean>(false);
  const [smsLoading, setSmsLoading] = useState<boolean>(false);

  const sendcode = () => {
    setSmsLoading(true);
    fetch_one<User>('/nl/v3/users/pre_login', {
      phone: phone,
      custom_redirect: window.location.host,
      embed_token: embed_token
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setShowCodeField(true);
      setSmsLoading(false);
    }, (z, y) => {
      setNotice(t("alerts.something_went_wrong"));
      setSmsLoading(false);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const sendLink = () => {
    fetch_one<User>('/nl/v3/users/pre_login', {
      email: email,
      custom_redirect: window.location.host,
      project_id: currentProject?.id,
      embed_token: embed_token
    }, (ob, complete_data) => {
      if (!!ob.custom_result) setNotice(t(ob.custom_result.notice || ''));
    }, (z, y) => {
      setNotice(t("alerts.something_went_wrong"));
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const subdomain = check_subdomain();
  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  let colWidth = 12;
  let loginPoss = 0;
  if ((!!currentProject?.is_itsme || subdomain === 'admin')) loginPoss += 1;
  if (!!currentProject?.is_email) loginPoss += 1;
  if (!!currentProject?.is_sms) loginPoss += 1;
  if (!!currentProject?.is_eid) loginPoss += 1;
  if (loginPoss < 2) colWidth = 12;
  if (loginPoss === 2) colWidth = 6;
  if (loginPoss === 3) colWidth = 4;
  if (loginPoss === 4) colWidth = 3;

  if (!!login || currentProject?.disable_login) {
    return <>
      <Box sx={{
        width: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box>
          <StyledButton
            label={t("login.cta.go_back_to_root")}
            id='back2root'
            contained
            onClick={(v) => {
              navigate("/");
            }}
            sx={{
              marginTop: 2
            }}
          />
        </Box>
      </Box>
    </>
  }

  return (<>
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box>
        <Typography variant="h1" sx={{textAlign: 'center', marginBottom: 4, textDecoration: 'underline'}}>{t("login.title")}</Typography>
      </Box>

      {!!showCodeField && <Grid container spacing={2}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Typography>{t("login.sms_code.sent_message")}</Typography>
        </Grid>
        <Grid item xs={12} md={3}></Grid>

        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <StyledTextField
            label={t("login.fields.sms_code")}
            value={smsCode}
            id="sms_code"
            onChange={(v) => {setSmsCode(v);}}
            fullWidth
            sx={{
              marginTop: 2
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}></Grid>

        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <StyledButton
            label={t("login.cta.submit_sms_code")}
            id='submit_sms_code'
            disabled={smsCode.length !== 6}
            contained
            onClick={(v) => {
              do_login_sms_code(smsCode, (result) => {
                setLogin(result);
                navigate("/poll");
              }, currentProject);
            }}
            sx={{
              marginTop: 2
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>}
      {notice.length > 0 && <Grid container spacing={2}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6} sx={{textAlign: 'center'}}>
          <Typography>{notice}</Typography>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>}
      {!showCodeField && notice.length < 1 && <Grid container spacing={2}>
        {(!!currentProject?.is_itsme || subdomain === 'admin') && <Grid item xs={12} md={colWidth}>
          <Box sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
            padding: 1,
            height: '100%',
            textAlign: 'center'
          }}>
            <img src={ItsmeLoginButton} style={{height: 60, cursor: 'pointer'}} onClick={() => {
              if (!isChecked) {
                setIsCheckedError(true);
              } else {
                //staging
                // window.location.href = `https://oidc.e2e.itsme.services/clientsecret-oidc/csapi/v0.1/connect/authorize?client_id=2bvhPRRbj7&response_type=code&scope=openid service:PALMEDIASBX_SHAREDAT profile email address phone&redirect_uri=https://api.panelmetrics.com/itsme/authentication&ui_locales=${((i18n.resolvedLanguage|| 'nl').length < 1 ? 'en' : (i18n.resolvedLanguage|| 'nl'))}&state=SUB${window.location.host}${!!embed_token ? `EMBED${embed_token}` : ''}`;
                //production
                window.location.href = `https://oidc.prd.itsme.services/clientsecret-oidc/csapi/v0.1/connect/authorize?client_id=c2DkdXNn4j&response_type=code&scope=openid service:PALMEDIAPRD_SHAREDATA profile email address phone&redirect_uri=https://api.panelmetrics.com/itsme/authentication&ui_locales=${((i18n.resolvedLanguage|| 'nl').length < 1 ? 'en' : (i18n.resolvedLanguage|| 'nl'))}&state=SUB${window.location.host}${!!embed_token ? `EMBED${embed_token}` : ''}`;
              }
            }} />
          </Box>
        </Grid>}
        {!!currentProject?.is_email && <Grid item xs={12} md={colWidth}>
          <Box sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
            padding: 1,
            height: '100%',
            textAlign: 'center'
          }}>
            <Grid item xs={12}>
              <StyledTextField
                label={t("login.fields.magic_link_email")}
                value={email}
                disabled={notice.length > 0}
                id="magic_link_email"
                onChange={(v) => {setEmail(v);}}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                label={t("login.cta.send_magic_link")}
                id='send_magic_link'
                contained
                onClick={(v) => {
                  if (!isChecked) {
                    setIsCheckedError(true);
                  } else {
                    sendLink();
                  }
                }}
                sx={{
                  marginTop: 2
                }}
              />
            </Grid>
          </Box>
        </Grid>}
        {!!currentProject?.is_sms && <Grid item xs={12} md={colWidth}>
          <Box sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            borderRadius: 4,
            padding: 1,
            height: '100%',
            textAlign: 'center'
          }}>
            <Grid item xs={12} sx={{
              "& > div": {
                paddingTop: '8px !important',
                paddingBottom: "8px !important",
              },
              "& > div > div:first-child": {
                marginTop: '8px'
              },
              "& > div > div": {
                top: '8px'
              },
              "& input": {
                width: '100% !important',
                paddingTop: "8px !important",
                paddingBottom: "8px !important",
              }
            }}>
              <PhoneInput
                country='be'
                regions={'europe'}
                preferredCountries={['be']}
                value={phone}
                onChange={phone => setPhone(phone)}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                label={t("login.cta.send_sms_code")}
                disabled={smsLoading}
                id='send_sms'
                contained
                onClick={(v) => {
                  if (!isChecked) {
                    setIsCheckedError(true);
                  } else {
                    sendcode();
                  }
                }}
                sx={{
                  marginTop: 2
                }}
              />
            </Grid>
          </Box>
        </Grid>}
        {!!currentProject?.is_eid && <Grid item xs={12} md={colWidth}>
          eid
        </Grid>}
      </Grid>}

      {!showCodeField && notice.length < 1 && <Box sx={{marginTop: 3, textAlign: 'center'}}>
        <Checkbox sx={{display: 'inline-block', padding: '4px', ...(isCheckedError ? {"& svg" : {color: 'red'}} : {})}} checked={isChecked} onChange={(v) => {
          setIsChecked(!isChecked);
          setIsCheckedError(false);
        }} />
        <Typography sx={{fontWeight: 'bold', display: 'inline-block', marginLeft: 1}}>{t("login.checkbox_confirm")}</Typography>
      </Box>}

      {!!login && <Typography sx={{color: theme.palette.secondary.main, textAlign: 'center'}}>{t("alerts.already_signed_in")}</Typography>}
      {!!login && <Link sx={{textAlign: 'center', marginTop: 2}} label={t("alerts.take_me_back").replace(/<[^>]*>?/gm, '')} onClick={() => {navigate("/");}} />}

      {!login && <Box sx={{display: 'flex', flexDirection: 'row', marginTop: 3}}>
        {/* {!(!!currentProject?.is_itsme || subdomain === 'admin') && <Checkbox sx={{padding: '4px', marginBottom: 'auto', marginTop: 1, ...(isCheckedError ? {"& svg" : {color: 'red'}} : {})}} checked={isChecked} onChange={(v) => {
          setIsChecked(!isChecked);
          setIsCheckedError(false);
        }} />} */}
        <Typography html sx={{color: theme.palette.primary_grey?.light, textAlign: 'center', fontSize: '0.8rem', fontStyle: 'italic', ...(!!nonmobile ? {} : {textAlign: 'left'})}}>
          {(!!currentProject && !!currentProject.privacy_check ? (currentProject.privacy_check[lang] || currentProject.privacy_check[fb_lang]) : t("login.consents.warning"))}
        </Typography>
        </Box>}
    </Box>
  </>);
}
