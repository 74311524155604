import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../../../lib/server_helper';
import { checkAllowed } from '../../../lib/permission';
import { loginAtom } from '../../../lib/auth';

import {
  Snackbar
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import { AlertSomethingWentWrong } from '../../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../../components/interface';

import { AlternateDomain } from '../../../models/AlternateDomain';

// const { DateTime } = require("luxon");

type Props = {};

export const CustomTaalklachtenList: FunctionComponent<Props> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);

  // const params = useParams();
  // const projectId: string | undefined = params.project_id;

  const [templates, setTemplates] = useState<{id: number; user_id: number; answers: any, custom_row: any}[]>([]);
  const [templatesCount, setTemplatesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  // const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  // Settings


  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    // if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectDomains", 'r', true)) {
      // console.log(filters);
      setLoader(true);
      fetch_all<{id: number; user_id: number; answers: any, custom_row: any}>('/nl/v3/objects/fetch_all', {
        object: 'answer_line',
        fields: ['id', 'answers'],
        per_page: 20,
        page: page,
        order: order,
        filter: {
          search: s,
          advanced: {
            project_id: 12,
            iteration: 1,
            fallback: s
          }
        }
      }, (obs, complete_data) => {
        setTemplates(obs);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setTemplatesCount(count);

        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    // } else {
    //   setLoader(false);
    // }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'user', headerName: 'User', width: 150, sortable: true },
    { field: 'status', headerName: 'Status', width: 150, sortable: true },
  ];

  return (
    <Page sx={{}} title={t("custom.vvb.tk.list.title")} actions={<>
      {/* {checkAllowed(login, null, projectId?.toString() || '0', "ProjectDomains", 'c', true) && <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={`/domain/${projectId}/new`}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />} */}
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            {/* {checkAllowed(login, null, projectId?.toString() || '0', "ProjectDomains", 'u', true) && <StyledIconButton title="Edit" color="primary" path={`/cust/taalklacht/${params.row.id}`}>
              <EditIcon />
            </StyledIconButton>} */}
            {/* {!!removeLoader && <CircularProgress />}
            {checkAllowed(login, null, projectId?.toString() || '0', "ProjectDomains", 'd', true) && !removeLoader && <StyledIconButton title="Destroy" destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>} */}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={templatesCount}
        objects={(templates || []).map(u => ({
          ...u,
          user: u.answers['112'],
          status: ["open", "pending", "closed"][u.custom_row.row_status]
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
