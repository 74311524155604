import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';

import { loginAtom } from '../lib/auth';
import { fetch_one, fetch_all } from '../lib/server_helper';
import { checkAllowed } from '../lib/permission';

import {
  Badge,
  Box,
  Divider
} from '@mui/material';

import ArticleIcon from '@mui/icons-material/Article';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CodeIcon from '@mui/icons-material/Code';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EmailIcon from '@mui/icons-material/Email';
import ExtensionIcon from '@mui/icons-material/Extension';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LinkIcon from '@mui/icons-material/Link';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SpeedIcon from '@mui/icons-material/Speed';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

import { MenuItem } from '../components/sidebar/MenuItem';
import { Profile } from '../components/sidebar/Profile';
import { SidebarCount } from '../models/SidebarCount';
import { SidebarQueues } from './SidebarQueues';

import { getCookie, setCookie } from 'react-use-cookie';

import {
  StyledSelect
} from './interface';

import { Project } from '../models/Project';

type SidebarProps = {};

export const Sidebar: FunctionComponent<SidebarProps> = () => {
  const theme = useTheme();
  const login = useAtomValue(loginAtom);
  let is_admin:boolean = login?.is_admin || false;

  const [badgeCounts, setBadgeCounts] = useState<SidebarCount|undefined>(undefined);
  const [searchInterval, setSearchInterval] = useState(setInterval(() => {}, 30 * 1000));
  const [myProjects, setMyProjects] = useState<Project[]>([]);
  const [activeProject, setActiveProject] = useState<string>(getCookie('panelmetrics_active_project_id') || '');
  const [activeProjectOb, setActiveProjectOb] = useState<Project>();

  useEffect(() => {
    clearTimeout(searchInterval);
    if (!!login) {
      onSearch();
      setSearchInterval(setInterval(onSearch, 30 * 1000));
    }
  }, [login]);

  const onSearch = () => {
    if (!!login) {
      // fetch_one<SidebarCount>('/nl/v3/objects/custom_action', {
      //   object: 'user',
      //   class_action: 'custom_api_get_sidebar_counts',
      //   handler_id: login.id
      // }, (obs, complete_data) => {
      //   setBadgeCounts(obs.custom_result);
      // }, (z) => {
  
      // }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const fetchMyProjects = () => {
    fetch_all<Project>('/nl/v3/objects/fetch_all', {
      object: 'project',
      fields: ['id', 'name', 'company|id/name', 'custom_key'],
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMyProjects(obs);
      setActiveProjectOb(obs.filter(p => p.id?.toString() === activeProject)[0]);
    }, (z) => {
      // setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    if (!!login) fetchMyProjects();
  }, [login]);

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height:'100%',
      backgroundColor: theme.palette.primary?.main,
      margin: -1,
      padding: 1
    }}>
      <Profile />

      <Box sx={{height: 40, paddingTop: 2}}>
        <StyledSelect
          label=""
          value={activeProject}
          id='p_id'
          onChange={(v) => {
            setCookie('panelmetrics_active_project_id', v);
            setActiveProject(v);
            setActiveProjectOb(myProjects.filter(p => p.id?.toString() === v)[0]);
          }}
          list={[
            {id: '', name: 'None selected'},
            ...myProjects.sort((a, b) => (a.company_name || '').localeCompare(b.company_name || '') || (a.name || '').localeCompare(b.name || '')).map(c => {return {id: (c.id || '').toString(), name: [c.company_name, c.name || ''].join(" - ")};})
          ]}
          sx={{
            maxWidth: '185px',
            "& svg": {
              color: 'white'
            },
            "& div": {
              color: 'white',
              fontSize: '0.7rem'
            },
            "& fieldset": {
              borderColor: 'white !important'
            }
          }}
        />
      </Box>

      <Box sx={{height: 40, paddingTop: 2, textAlign: 'right'}}>
        <SidebarQueues />
      </Box>

      <MenuItem path="/dashboard" label="Dashboard" icon={<SpeedIcon />} />
      
      {login?.is_special_permissions && <MenuItem hidden={!is_admin} label="Content" icon={<FormatListBulletedIcon />}>
        <MenuItem hidden={!is_admin} path="/cities" label="Cities" child />
        <MenuItem hidden={!is_admin} path={`/faqs/${activeProject}`} label="Faqs" child />
        {/* <MenuItem hidden={!is_admin} path="/mailer" label="Mailer" child /> */}
        <MenuItem hidden={!is_admin} path="/statics" label="Statics" child />
      </MenuItem>}
      
      {login?.is_special_permissions && <MenuItem hidden={!is_admin} path="/companies" label="Companies" icon={<BusinessIcon />} />}
      {<MenuItem hidden={!is_admin} path="/projects" label="Projects" icon={<WorkspacesIcon />} />}
      {checkAllowed(login, null, null, "Users", 'r', true, true) && <MenuItem hidden={!is_admin} path="/users" label="Users" icon={<PeopleAltIcon />} />}
      {<MenuItem hidden={!is_admin} path="/mailer" label="Mailer" icon={<EmailIcon />} />}
      {<MenuItem hidden={!is_admin} path="/segments" label="Segments" icon={<ExtensionIcon />} />}
      {/* {(login?.is_special_permissions || (!!login && checkAllowed(login, null, null, "Projects", 'r'))) && <MenuItem hidden={!is_admin} path="/projects" label="Projects" icon={<WorkspacesIcon />} />}
      {(login?.is_special_permissions || (!!login && checkAllowed(login, null, null, "Users", 'r'))) && <MenuItem hidden={!is_admin} path="/users" label="Users" icon={<PeopleAltIcon />} />} */}
      
      {/* <MenuItem hidden={!is_admin} path="/exports" label="Exports" icon={<BarChartIcon />} /> */}
      
      {/* <MenuItem hidden={!is_admin} label="Platform" icon={<EngineeringIcon />}>
        <MenuItem hidden={!is_admin} path="/system_settings" label="System Settings" child />
      </MenuItem> */}
      {!!activeProject && myProjects.map(p => p.id?.toString()).indexOf(activeProject) > -1 && <>
        <Box sx={{marginTop: 2, backgroundColor: 'white', width: '100%', maxWidth: '180px', height: '1px'}} />
        {(checkAllowed(login, activeProjectOb?.company_id?.toString() || '0', activeProject, "ProjectDomains", 'r')) && <MenuItem hidden={!is_admin} path={`/domains/${activeProject}`} label="Domains" icon={<LinkIcon />} />}
        {(checkAllowed(login, activeProjectOb?.company_id?.toString() || '0', activeProject, "ProjectEmbeddables", 'r')) && <MenuItem hidden={!is_admin} path={`/embeddables/${activeProject}`} label="Embeddables" icon={<CodeIcon />} />}
        {(checkAllowed(login, activeProjectOb?.company_id?.toString() || '0', activeProject, "ProjectPages", 'r')) && <MenuItem hidden={!is_admin} path={`/pages/${activeProject}`} label="Pages" icon={<ArticleIcon />} />}
        {(checkAllowed(login, activeProjectOb?.company_id?.toString() || '0', activeProject, "ProjectQuestions", 'r')) && <MenuItem hidden={!is_admin} path={`/questions/${activeProject}`} label="Questions" icon={<QuestionMarkIcon />} />}
        {<MenuItem hidden={!is_admin} path={`/reports/${activeProject}`} label="Reports" icon={<StackedLineChartIcon />} />}
        {(checkAllowed(login, activeProjectOb?.company_id?.toString() || '0', activeProject, "ProjectExport", 'r')) && <MenuItem hidden={!is_admin} path={`/stats/${activeProject}`} label="Stats & Export" icon={<PieChartIcon />} />}
        {<MenuItem hidden={!is_admin} path={`/user_invites/${activeProject}`} label="User Invites" icon={<GroupAddIcon />} />}
        {!!activeProjectOb && activeProjectOb.custom_key === '2024_taalklacht_dashboard' && <>
          <Box sx={{marginTop: 2, backgroundColor: 'white', width: '100%', maxWidth: '180px', height: '1px'}} />
          {<MenuItem hidden={!is_admin} path={`/cust/taalklachten/dashboard`} label="TK Dashboard" icon={<BarChartIcon />} />}
          {<MenuItem hidden={!is_admin} path={`/cust/taalklachten/list`} label="TK List" icon={<ChecklistIcon />} />}
        </>}
      </>}
    </Box>
  );
}