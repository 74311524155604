import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { loginAtom } from '../../lib/auth';
import { fetchPage } from '../../lib/page';
import { currentProjectAtom } from '../../lib/project';

import {
  Box
} from '@mui/material';

import {
  Typography
} from '../../components/interface';
import { Helmet } from 'react-helmet';

import { Page } from '../../models/Page';

type PubContentProps = {};

export const PubContent: FunctionComponent<PubContentProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);
  let location = useLocation();

  const params = useParams();

  // const [currentProject, setCurrentProject] = useState<Project|null>(null);
  const [currentPage, setCurrentPage] = useState<Page|null>(null);
  const [pageKey, setPageKey] = useState<string | undefined>(params.page_key);
  
  useEffect(() => {
    setPageKey(params.page_key);
  }, [location.pathname]);
  
  useEffect(() => {
    fetchPage(pageKey === 'landing' ? 'project_landing' : 'content', (ob) => {setCurrentPage(ob);}, login, pageKey);
  }, [currentProject, pageKey]);

  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  return (<>
    {!!currentPage && !!currentPage.page_title && <Helmet>
      <title>{currentPage?.page_title[lang] || currentPage?.page_title[fb_lang]}</title>
    </Helmet>}
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!!currentPage && !!currentPage.body && <>
        <Typography html sx={{
          ...(currentProject?.id === 12 ? {
            "& .se-component.se-image-container": {
              display: 'inline-block'
            },
            "& .se-component.se-image-container.__se__float-none": {
              width: '100%'
            },
            "& .se-component.se-image-container.__se__float-none figure": {
              width: '100%',
              margin: 0
            }
          } : {})
        }}>{currentPage.body[lang] || currentPage.body[fb_lang]}</Typography>
      </>}
    </Box>
  </>);
}
