import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import {
  LanguageFab,
  Page as WebPage,
  StyledButton,
  StyledDropzone,
  StyledEditor,
  StyledEditorNoTransl,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../components/interface';
import { PickersCompanyPicker} from '../../components/pickers/CompanyPicker';
import { PickersLanguagePicker} from '../../components/pickers/LanguagePicker';

// CONFIG 1/6
import { AlternateDomain } from '../../models/AlternateDomain';
import { Company } from '../../models/Company';
import { Faq } from '../../models/Faq';
import { Page } from '../../models/Page';
import { Project } from '../../models/Project';
import { Static } from '../../models/Static';

import { getCookie, setCookie } from 'react-use-cookie';

type AvailableLang = 'nl' | 'fr' | 'en';
// CONFIG 2/6
type ContentEditPageProps = {
  object_type: 'banner' | 'static' | 'company' | 'project' | 'page' | 'domain' | 'faq';
  return_path: string;
};

export const ContentEditPage: FunctionComponent<ContentEditPageProps> = ({object_type, return_path}) => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const { state } = useLocation();
  const params = useParams();
  const objectId: string | undefined = params.id;
  const projectId: string | undefined = params.project_id;

  const [myProjects, setMyProjects] = useState<Project[]>([]);
  const [activeProject, setActiveProject] = useState<string>(getCookie('panelmetrics_active_project_id') || '');
  const [activeProjectOb, setActiveProjectOb] = useState<Project>();

  if (!!projectId) return_path = return_path.replace(":project_id", projectId.toString());

  const [login, setLogin] = useAtom(loginAtom);
  const [editableObject, setEditableObject] = useState<any>({
    ...(!!state && !!state.ob ? state.ob : {})
  });
  const [loader, setLoader] = useState<boolean>(true);
  const [err, setErr] = useState<string>();
  const [activeLocale, setActiveLocale] = useState<AvailableLang>('en');
  const [item, setItem] = useState<File>();

  const fetchMyProjects = () => {
    fetch_all<Project>('/nl/v3/objects/fetch_all', {
      object: 'project',
      fields: ['id', 'name', 'company|id/name', 'custom_key'],
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMyProjects(obs);
      setActiveProjectOb(obs.filter(p => p.id?.toString() === activeProject)[0]);
    }, (z) => {
      // setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    if (!!login) fetchMyProjects();
  }, [login]);

  let setting_languages_split_raw = '0';
  if (!!login && !!login.extra_data?.settings?.languages_split) setting_languages_split_raw = login.extra_data.settings.languages_split;
  let setting_languages_split = setting_languages_split_raw === "1";

// CONFIG 3/6
  const fields = {
    'banner': ['id', 'body', 'cta_text', 'cta_link', 'is_stock', 'is_who', 'get_item', 'item_blob|content_type', 'item|filename/byte_size'],
    'static': ['id', 'identifier', 'alternate_name', 'body', 'company|id/name', 'project|id/name'],
    'company': ['id', 'name', 'enabled', 'vat_number', 'vat_amount', 'street', 'zip', 'city', 'country'],
    'faq': ['id', 'question', 'body', 'sort_order'],
    'domain': ['id', 'project_id', 'full_url'],
    'project': ['id', 'name', 'enabled', 'company_id', 'languages', 'is_itsme', 'iteration', 'project_type', 'subdomain', 'get_item', 'show_landing', 'privacy_url', 'privacy_check', 'is_email', 'is_sms', 'is_eid', 'closed_circle', 'custom_key', 'disable_login'],
    'page': ['id', 'page_type', 'title', 'body', 'page_title', 'page_key']
  };

  const fetchEditableObject = () => {
    setLoader(true);
    
    if (!!objectId) {
      if (!!login) {
        let pars = {
          object: object_type === 'domain' ? 'alternate_domain' : object_type,
          fields: fields[object_type],
          id: objectId
        };

// CONFIG 4/6
        if (object_type === 'company') fetch_one<Company>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
        if (object_type === 'domain') fetch_one<AlternateDomain>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
        if (object_type === 'faq') fetch_one<Faq>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
        if (object_type === 'page') fetch_one<Page>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
        if (object_type === 'project') fetch_one<Project>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
        if (object_type === 'static') fetch_one<Static>('/nl/v3/objects/fetch_all', pars, (ob, complete_data) => {setEditableObject(ob); setLoader(false);}, (z) => {}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
      }
    } else {
      setLoader(false);
    }
  };

  const buildFormData = (formData:FormData, data:object|any, parentKey:string|undefined) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      if (Array.isArray(data)) {
        Object.values(data).forEach(value => {
          if (!!value) formData.append(`${parentKey}[]`, value.toString());
        });
      }  else {
        Object.keys(data).forEach(key => {
          if (key !== 'get_item') buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      }
    } else {
      const value = data == null ? '' : data;
  
      if (!!parentKey) formData.append(parentKey, value);
    }
  }

  const saveEditableObject = (navigate_away:boolean) => {
    setLoader(true);
    
    if (!!editableObject) {
      let pars = {
        object: object_type === 'domain' ? 'alternate_domain' : object_type,
        fields: fields[object_type],
        ob: editableObject
      };

      if (!!projectId && object_type === 'domain') {
        pars.ob = {...pars.ob, project_id: projectId}
      }

      const formData = new FormData();
      buildFormData(formData, pars, undefined);
      if (!!item) formData.append('files[item]', item);
      
// CONFIG 5/6
      if (object_type === 'company') fetch_one<Company>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {setLoader(false); setErr(z);}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
      if (object_type === 'domain') fetch_one<AlternateDomain>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {setLoader(false); setErr(z);}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
      if (object_type === 'faq') fetch_one<Faq>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {setLoader(false); setErr(z);}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
      if (object_type === 'page') fetch_one<Page>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {setLoader(false); setErr(z);}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
      if (object_type === 'project') fetch_one<Project>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {setLoader(false); setErr(z);}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
      if (object_type === 'static') fetch_one<Static>('/nl/v3/objects/save', formData, (ob, complete_data) => {setEditableObject(ob); setLoader(false); if (navigate_away) {navigate(return_path);}; if (!navigate_away) {setEditableObject({});};}, (z) => {setLoader(false); setErr(z);}, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  useEffect(() => {
    setEditableObject({...editableObject, project_id: projectId});
  }, [projectId]);

  useEffect(() => {
    fetchEditableObject();
  }, []);

// CONFIG 6/6
  let formDetails = [];
  // [gridsize (unused when translatable), type, translatable, label, field, title]
  // if (object_type === 'banner') {
  //   formDetails.push([12, 'editor', true, 'Body', 'body']);
  //   formDetails.push([4, 'switch', false, 'Is Stock', 'is_stock']);
  //   formDetails.push([4, 'switch', false, 'Is Who', 'is_who']);
  //   formDetails.push([6, 'string', true, 'CTA text', 'cta_text']);
  //   formDetails.push([6, 'string', true, 'CTA link', 'cta_link']);
  //   formDetails.push([12, 'upload', false, 'Item', 'item']);
  // }

  // if (object_type === 'faq') {
  //   formDetails.push([12, 'string', true, 'Title', 'title']);
  //   formDetails.push([12, 'editor', true, 'Body', 'body']);
  //   formDetails.push([4, 'switch', false, 'Is active', 'enabled']);
  // }

  if (object_type === 'company') {
    formDetails.push([12, 'string', false, 'Name', 'name']);
    formDetails.push([6, 'string', false, 'VAT number', 'vat_number']);
    formDetails.push([6, 'string', false, 'VAT amount', 'vat_amount']);
    formDetails.push([12, 'string', false, 'Street', 'street']);
    formDetails.push([4, 'string', false, 'ZIP', 'zip']);
    formDetails.push([8, 'string', false, 'City', 'city']);
    formDetails.push([12, 'string', false, 'Country', 'country']);
    formDetails.push([4, 'switch', false, 'Enabled', 'enabled']);
  }

  if (object_type === 'domain') {
    formDetails.push([12, 'string', false, 'Full URL', 'full_url']);
  }

  if (object_type === 'faq') {
    formDetails.push([12, 'string', true, 'Question', 'question']);
    formDetails.push([12, 'editor', true, 'Body', 'body']);
    formDetails.push([6, 'string', false, 'Sort order (ASC)', 'sort_order']);
  }

  if (object_type === 'page') {
    formDetails.push([6, 'page_types', false, 'Type', 'page_type']);
    formDetails.push([6, 'string', false, 'Key', 'page_key']);
    formDetails.push([12, 'string', true, 'Title', 'page_title']);
    formDetails.push([12, 'editor', true, 'Body', 'body']);
  }

  if (object_type === 'project') {
    formDetails.push([12, 'string', false, 'Name', 'name']);
    formDetails.push([6, 'company_picker', false]);
    formDetails.push([6, 'string', false, 'Subdomain (.panelmetrics.com)', 'subdomain']);
    formDetails.push([6, 'string', false, 'Project type', 'project_type']);
    formDetails.push([6, 'string', false, 'Iteration', 'iteration']);
    formDetails.push([4, 'switch', false, 'Enabled', 'enabled']);
    formDetails.push([4, 'switch', false, 'Show Project Landing (if available)', 'show_landing']);
    formDetails.push([12, 'language_picker', false, 'Languages', 'languages']);
    formDetails.push([12, 'subtitle', false, 'Login possibilities']);
    formDetails.push([3, 'switch', false, 'Login Disabled', 'disable_login']);
    formDetails.push([3, 'switch', false, 'Itsme enabled', 'is_itsme', editableObject.disable_login]);
    formDetails.push([3, 'switch', false, 'Email enabled', 'is_email', editableObject.disable_login]);
    formDetails.push([3, 'switch', false, 'SMS enabled', 'is_sms', editableObject.disable_login]);
    formDetails.push([12, 'subtitle', false, 'Other settings']);
    formDetails.push([4, 'switch', false, 'UserInvites as whitelist', 'closed_circle']);
    formDetails.push([12, 'subtitle', false, 'Privacy stuff']);
    formDetails.push([12, 'line', false, '', '']);
    formDetails.push([12, 'string', false, 'Privacy URL', 'privacy_url']);
    formDetails.push([12, 'editor', true, 'Privacy Checkbox', 'privacy_check']);
    formDetails.push([12, 'subtitle', false, 'Custom stuff']);
    formDetails.push([6, 'string', false, 'Custom Key', 'custom_key']);
  }

  if (object_type === 'static') {
    formDetails.push([12, 'string', false, 'Identifier', 'identifier']);
    formDetails.push([12, 'string', false, 'Alternate names', 'alternate_name', <>Separate with a pipe ( | ) to use multiple alternate names. <StyledButton label="Add a pipe" id='add_pipe' contained onClick={(v) => {
      if (!!editableObject) setEditableObject({
        ...editableObject,
        alternate_name: `${(editableObject.alternate_name || '')} | `
      });
    }} sx={{marginLeft: 1, paddingTop: 0, paddingBottom: 0, scale: '0.8'}} /></>]);
    formDetails.push([12, 'editor', true, 'Body', 'body']);
  }

  // if (object_type === 'tag') {
  //   formDetails.push([12, 'string', true, 'Name', 'name']);
  // }

  let is_multi = false;
  formDetails.map(formDetail => {
    if (!is_multi) is_multi = !!formDetail[2];
  })

  return (
    <WebPage sx={{
      ...(object_type === 'banner' ? {
        ".sun-editor-editable": {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      } : {})
    }} title={`Edit ${object_type}${!!is_multi ? ` (${activeLocale})` : ''}`}>
      {!!is_multi && <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {console.log(lc); setActiveLocale(lc);}}
      />}
      <Grid container spacing="8">

        {formDetails.map(formData => <>

          {formData[1] === 'string' && <>
            {!!formData[2] && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <StyledTextFieldTransl
                label={formData[3].toString()}
                activeLocale={activeLocale}
                value={editableObject[formData[4].toString()] || {en: ''}}
                id={formData[4].toString()}
                onChange={(v) => {if (!!editableObject) setEditableObject({
                  ...editableObject,
                  [formData[4].toString()]: v});
                }}
                fullWidth
                helperText={formData[5]}
              />
            </Grid>}
            {!formData[2] && <Grid item xs={parseInt(formData[0].toString(), 10)}>
              <StyledTextField
                label={formData[3].toString()}
                value={editableObject[formData[4].toString()] || ''}
                id={formData[4].toString()}
                onChange={(v) => {if (!!editableObject) setEditableObject({
                  ...editableObject,
                  [formData[4].toString()]: v});
                }}
                fullWidth
                helperText={formData[5]}
              />
            </Grid>}
          </>}

          {formData[1] === 'editor' && (!!formData[2] ? <StyledEditor
            label={formData[3].toString()}
            value={editableObject[formData[4].toString()] || {en: ''}}
            activeLocale={activeLocale}
            onChange={(ob) => {
              if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: ob
              });
            }}
          /> : <StyledEditorNoTransl
            label={formData[3].toString()}
            value={editableObject[formData[4].toString()] || ''}
            onChange={(ob) => {
              if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: ob
              });
            }}
          />)}

          {formData[1] === 'switch' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <StyledSwitch
              label={formData[3].toString()}
              readonly={!!formData[5]}
              value={!!editableObject[formData[4].toString()]}
              onChange={(v) => {if (!!editableObject) setEditableObject({
                ...editableObject,
                [formData[4].toString()]: !!v
              });}}
            />
          </Grid>}

          {formData[1] === 'line' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <Divider />
          </Grid>}

          {formData[1] === 'company_picker' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <PickersCompanyPicker
              value={editableObject.company_id?.toString() || ''}
              onChange={(ob) => {
                if (!!editableObject) setEditableObject({
                  ...editableObject,
                  company_id: parseInt(ob, 10)
                });
              }}
            />
          </Grid>}

          {formData[1] === 'language_picker' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <PickersLanguagePicker
              value={(editableObject[formData[4].toString()] || '').split(";")}
              onChange={(languages) => {
                if (!!editableObject) setEditableObject({
                  ...editableObject,
                  [formData[4].toString()]: languages.map(l => {return l as string;}).join(";")
                });
              }}
            />
          </Grid>}

          {formData[1] === 'subtitle' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <Typography variant="h3" sx={{borderBottom: '1px solid lightgrey', marginTop: 6}}>{formData[3]}</Typography>
          </Grid>}

          {formData[1] === 'page_types' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <StyledSelect
              label="Page types"
              value={(editableObject[formData[4].toString()] || '')}
              id='page_types'
              onChange={(v) => {
                if (!!editableObject) setEditableObject({
                  ...editableObject,
                  page_type: v
                });
              }}
              list={[
                {id: 'index', name: 'Index page'},
                {id: 'content', name: 'Content page'},
                {id: 'poll_intro', name: 'Poll intro'},
                {id: 'poll_exit', name: 'Poll exit'},
                {id: 'poll_outro', name: 'Poll outro'},
                {id: 'project_landing', name: 'Project Landing'},
                {id: 'project_ending', name: 'Project Ending'}
              ]}
            />
          </Grid>}

          {formData[1] === 'upload' && formData[4] === 'item' && <Grid item xs={parseInt(formData[0].toString(), 10)}>
            <Typography sx={{fontSize: '0.75rem'}}>Item</Typography>
            <StyledDropzone
              acceptedFiles={{}}
              onDrop={acceptedFiles => {setItem(acceptedFiles[0]);}}
              multiple={false}
              maxFiles={1}
              maxSize={3 * 1000 * 1000}
              items={!!item ? [item] : []}
              dropText="Click here, or drop some files."
              dropHint="Allowed: 1 item < 3mb"
            />
            {!!editableObject?.item_filename && <Typography sx={{fontSize: '0.75rem'}}>Current: {editableObject?.item_filename} ({(parseInt(editableObject?.item_byte_size || '0', 10) / (1000*1000)).toFixed(2)}mb)</Typography>}
          </Grid>}

        </>)}
        
        <Grid item xs={12} spacing="8" sx={{alignContent: 'baseline', textAlign: 'center'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {/* {!loader && <StyledButton
            label="Save and re-open"
            id='save_reopen'
            contained
            saveStartIcon
            onClick={(v) => {
              saveEditableObject(false);
            }}
          />} */}
          {!loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            saveStartIcon
            onClick={(v) => {
              saveEditableObject(true);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(return_path);
            }}
            sx={{marginLeft: 1}}
          />}
          {object_type === 'static' && !!editableObject.project_id && <Typography sx={{color: 'orange', fontStyle: 'italic', marginTop: 2, fontSize: '0.8rem'}}>Overriding for project {activeProjectOb?.id} ({activeProjectOb?.name || '<unknown>'})</Typography>}
          {!!err && <Typography sx={{color: 'red', fontStyle: 'italic', marginTop: 2, fontSize: '0.8rem'}}>{err}</Typography>}
          {!!err && object_type === 'static' && <StyledButton
            label={t("public.buttons.copy_static")}
            id='copy_static'
            // contained
            onClick={(v) => {
              setErr(undefined);
              setEditableObject({
                identifier: editableObject.identifier,
                alternate_name: editableObject.alternate_name,
                body: editableObject.body,
                company_id: activeProjectOb?.company_id,
                project_id: activeProjectOb?.id
              });
            }}
            sx={{marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </WebPage>
  );
}
