import { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { loginAtom } from '../../../../lib/auth';
import { fetch_one } from '../../../../lib/server_helper';
import { currentProjectAtom } from '../../../../lib/project';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import {
  StyledButton,
  StyledTextField,
  Typography
} from '../../../../components/interface';
import { AlertSaved } from '../../../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../../../components/alerts/SomethingWentWrong';
import { Helmet } from 'react-helmet';

import { GoogleReCaptchaProvider, GoogleReCaptchaCheckbox } from '@google-recaptcha/react';

type Props = {};

export const PubCustomTaalklachtenContact: FunctionComponent<Props> = () => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);

  const [rcToken, setRcToken] = useState<string>();
  const [loader, setLoader] = useState<boolean>(false);
  const [showSucc, setShowSucc] = useState<boolean>(false);
  const [savedSnackOpen, setSavedSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>();
  const [currContact, setCurrContact] = useState<{
    name: string;
    email: string;
    phone: string;
    where: string;
    description: string;
    time: string;
  }>({
    name: '',
    email: '',
    phone: '',
    where: '',
    description: '',
    time: ''
  });

  const onSetToken = useCallback((googleReCaptchaToken: string) => {
    // console.log(googleReCaptchaToken);
    setRcToken(googleReCaptchaToken);
  }, [])
  const onUnSetToken = useCallback(() => {
    setRcToken(undefined);
  }, [])
  const saveContact = () => {
    setLoader(true);

    if (!currContact.name || !currContact.email || !currContact.phone || !currContact.where || !currContact.description || !currContact.time) return false;

    let formData = new FormData();
    formData.append('object', 'contact');
    formData.append('class_action', 'custom_api_send_contact');

    formData.append(`rc_token`, (rcToken || ''));
    
    formData.append(`ob[project_id]`, (currentProject?.id || '').toString());
    formData.append(`ob[extra_data][name]`, (currContact.name || ''));
    formData.append(`ob[extra_data][email]`, (currContact.email || ''));
    formData.append(`ob[extra_data][phone]`, (currContact.phone || ''));
    formData.append(`ob[extra_data][where]`, (currContact.where || ''));
    formData.append(`ob[extra_data][description]`, (currContact.description || ''));
    formData.append(`ob[extra_data][time]`, (currContact.time || ''));

    fetch_one<{
      custom_result: {success: boolean}
    }>('/nl/v3/pub/custom_action', formData, (ob, complete_data) => {
      if (ob.custom_result.success) {
        setSavedSnackOpen(true);
        setShowSucc(true);
      } else {
        setServerErrorSnackOpen(true);
      }
      setLoader(false);
    }, (z) => {
      setServerError(z);
      setServerErrorSnackOpen(true);
      setLoader(false);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };
  
  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  return (<>
    <Helmet>
      <title>Klacht indienen</title>
    </Helmet>
    {!!currentProject && currentProject?.id === 12 && <Grid container spacing="8">
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="h1">{t("public.contact.title")}</Typography>
      </Grid>

      {!!loader && <Grid item xs={12} sx={{textAlign: 'center'}}><CircularProgress /></Grid>}
      {!!showSucc && <>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: 3}}>
          <Typography>{t("custom.taalklacht.contact.success")}</Typography>
          <StyledButton
            label={t("public.buttons.back_to_home")}
            id='back'
            contained
            onClick={(v) => {
              navigate("/");
            }}
          />
        </Grid>
      </>}
      {!showSucc && !loader && <>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: 3}}>
          <Typography variant="h4" sx={{fontWeight: 'bold'}}>{t("custom.taalklacht.contact.sections.contact.title")}</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledTextField
            label={t("custom.taalklacht.contact.fields.name")}
            value={currContact.name || ''}
            id="name"
            onChange={(v) => {
              setCurrContact({
                ...currContact,
                name: v
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            label={t("custom.taalklacht.contact.fields.email")}
            value={currContact.email || ''}
            id="email"
            onChange={(v) => {
              setCurrContact({
                ...currContact,
                email: v
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            label={t("custom.taalklacht.contact.fields.phone")}
            value={currContact.phone || ''}
            id="phone"
            onChange={(v) => {
              setCurrContact({
                ...currContact,
                phone: v
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: 3}}>
          <Typography variant="h4" sx={{fontWeight: 'bold'}}>{t("custom.taalklacht.contact.sections.incident.title")}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            label={t("custom.taalklacht.contact.fields.where")}
            value={currContact.where || ''}
            id="where"
            onChange={(v) => {
              setCurrContact({
                ...currContact,
                where: v
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            label={t("custom.taalklacht.contact.fields.when")}
            value={currContact.time || ''}
            id="time"
            onChange={(v) => {
              setCurrContact({
                ...currContact,
                time: v
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledTextField
            label={t("custom.taalklacht.contact.fields.description")}
            value={currContact.description || ''}
            id="description"
            multiline
            onChange={(v) => {
              setCurrContact({
                ...currContact,
                description: v
              });
            }}
            fullWidth
          />
        </Grid>
        {!(!currContact.name || !currContact.email || !currContact.phone || !currContact.where || !currContact.description || !currContact.time) && <Grid item xs={12}>
          <GoogleReCaptchaProvider
            type="v2-checkbox"
            siteKey="6LfMu7cqAAAAAAkTG2PBNdMUIl8_kmSpzgUrS8b9"
          >
            <GoogleReCaptchaCheckbox
              onChange={onSetToken}
              expiredCallback={onUnSetToken}
            />
          </GoogleReCaptchaProvider>
        </Grid>}


        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/`);
            }}
            // sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            disabled={!currContact.name || !currContact.email || !currContact.phone || !currContact.where || !currContact.description || !currContact.time || !rcToken}
            contained
            onClick={(v) => {
              saveContact();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </>}
    </Grid>}

    {!!savedSnackOpen && <AlertSaved open={savedSnackOpen} setOpen={(b) => {setSavedSnackOpen(b);}} />}
    {!!serverErrorSnackOpen && <AlertSomethingWentWrong extra={serverError} open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
  </>);
}
