import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { loginAtom } from '../../lib/auth';
import { fetch_all } from '../../lib/server_helper';
import { currentProjectAtom } from '../../lib/project';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  Box,
  Divider
} from '@mui/material';

import {
  Typography
} from '../../components/interface';

import { Faq } from '../../models/Faq';
import { Helmet } from 'react-helmet';

type Props = {};

export const PubFaq: FunctionComponent<Props> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);

  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [current, setCurrent] = useState<number>(-1);

  const getFaqs = () => {
    fetch_all<Faq>('/nl/v3/pub/fetch_faqs', {
      fields: ['id', 'question', 'body'],
      subdomain: currentProject?.subdomain
    }, (obs, complete_data) => {
      setFaqs(obs);
    }, (z) => {
      
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    getFaqs();
  }, []);
  
  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  return (<>
    <Helmet>
      <title>Veelgestelde vragen</title>
    </Helmet>
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!!faqs && faqs.map(faq => <Box sx={{
        padding: 2
      }}>
        <Typography html sx={{
          cursor: 'pointer'
        }} onClick={() => {
          setCurrent(faq.id || 0);
        }}>{current === faq.id && <KeyboardArrowDownIcon sx={{marginRight: 1, marginBottom: -1}} />}{current !== faq.id && <KeyboardArrowRightIcon sx={{marginRight: 1, marginBottom: -1}} />}{(faq.question || {})[lang] || (faq.question || {})[fb_lang]}</Typography>
        {current === faq.id && <Divider sx={{marginBottom: 2, marginTop: 1}} />}
        <Typography html sx={{
          display: (current === faq.id ? 'block' :'none')
        }}>{(faq.body || {})[lang] || (faq.body || {})[fb_lang]}</Typography>
      </Box>)}
    </Box>
  </>);
}
