import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';

// import {
//   Avatar
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton
} from '../../components/interface';

import { Faq } from '../../models/Faq';

type ContentFaqsProps = {};

export const ContentFaqs: FunctionComponent<ContentFaqsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();
  const [projectId, setProjectId] = useState<string | undefined>(params.project_id);

  const [login, setLogin] = useAtom(loginAtom);
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [faqsCount, setFaqsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("sort_order ASC");

  useEffect(() => {
    onSearch('');
  }, [page, order, projectId]);
  useEffect(() => {
    setProjectId(params.project_id);
  }, [params.project_id]);

  const onRemove = (id: number) => {
    if (login) {
      setRemoveLoader(true);
      fetch_one<Faq>('/nl/v3/objects/remove', {
        object: 'faq',
        ob: {
          id: id
        }
      }, (obs, complete_data) => {
        onSearch('');
        setRemoveLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const onSearch = (s: string) => {
    if (login) {
      setLoader(true);
      fetch_all<Faq>('/nl/v3/objects/fetch_all', {
        object: 'faq',
        fields: ['id', 'question', 'sort_order'],
        per_page: 20,
        page: page,
        order: order,
        filter: {
          search: s
        }
      }, (obs, complete_data) => {
        setFaqs(obs);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setFaqsCount(count);

        setLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'question_nl', headerName: 'Question', width: 300, sortable: false },
    { field: 'sort_order', headerName: 'Sort Order', width: 150 },
  ];

  return (
    <Page sx={{}} title={t("objects.faqs.title")} actions={<>
      {login && !!projectId && <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={`/faq/${projectId}/new`}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />}
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="faqs"
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            {login && <StyledIconButton color="primary" path={`/faq/${projectId}/${params.row.id}`}>
              <EditIcon />
            </StyledIconButton>}
            {login && !removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={faqsCount}
        objects={(faqs || []).map(u => ({
          ...u,
          question_nl: u.question?.nl || u.question?.en || ''
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
