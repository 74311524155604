import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { checkConditionsForAnswerOption } from '../../lib/poll';
import { currentProjectAtom } from '../../lib/project';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';

import MainLogo from '../images/MainLogo.png';

import {
  Typography,
  StyledTextField,
  StyledDateField
} from '../../components/interface';

import { SlideLayoutMultiNormal } from './slide_layouts/MultiNormal';
import { SlideLayoutMultiElectionForm } from './slide_layouts/MultiElectionForm';
import { SlideLayoutNpsNps } from './slide_layouts/NpsNps';
import { SlideLayoutSingleNormal } from './slide_layouts/SingleNormal';
import { SlideLayoutSingleElectionForm } from './slide_layouts/SingleElectionForm';

import { Answer } from '../../models/Answer';
import { AnswerOption } from '../../models/AnswerOption';
import { User } from '../../models/User';

type SharedPollSlideProps = {
  currentQuestion: {
    body: {[x:string]: string};
    nps_unlikely: {[x:string]: string};
    nps_likely: {[x:string]: string};
    question_type: string;
    id: number;
    is_optional: boolean;
    is_extended_profile: boolean;
    show_outro_type: string;
    answer_options: AnswerOption[];
    extra_data: {
      alignment: string;
      layout?: string;
      open_type?: string;
    };
  };
  pollAnswers: Answer[];
  setPollAnswers: (a:Answer[]) => void;
  step: number;
  userProfile?: User;
  // setStep: (n:number) => void;
};

export const SharedPollSlide: FunctionComponent<SharedPollSlideProps> = ({currentQuestion, pollAnswers, setPollAnswers, step, userProfile}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const currentProject = useAtomValue(currentProjectAtom);


  const fb_lang:string = currentProject?.fallback_language|| 'nl';
  const lang:string = i18n.resolvedLanguage || fb_lang;

  return (<Grid item xs={12}>
    {(currentQuestion.extra_data?.layout || 'normal') === 'normal' && <Typography sx={{
      textAlign: (currentQuestion?.extra_data?.alignment || 'left')
    }} html>{currentQuestion.body[lang] || currentQuestion.body[fb_lang]}</Typography>}

    {currentQuestion.question_type === 'open' && <>
      {((currentQuestion?.extra_data?.open_type || 'short_text') === 'short_text' || (currentQuestion?.extra_data?.open_type || 'short_text') === 'long_text') && <StyledTextField
        label={t("pub.poll.open_answer")}
        value={!!pollAnswers[step] ? (pollAnswers[step].answer_content || '') : ''}
        multiline={(currentQuestion?.extra_data?.open_type || 'short_text') === 'long_text'}
        id="open_answer"
        onChange={(v) => {
          let new_poll_answers = [...pollAnswers];

          new_poll_answers[step] = {
            ...pollAnswers[step],
            question_id: !!currentQuestion ? currentQuestion.id : undefined,
            iteration: currentProject?.iteration || 1,
            answer_content: v
          };

          setPollAnswers(new_poll_answers);
        }}
        fullWidth
      />}
      {((currentQuestion?.extra_data?.open_type || 'short_text') === 'date') && <StyledDateField
        label={t("pub.poll.open_answer")}
        value={!!pollAnswers[step] ? (pollAnswers[step].answer_content || '') : ''}
        id="open_answer"
        onChange={(v) => {
          let new_poll_answers = [...pollAnswers];

          new_poll_answers[step] = {
            ...pollAnswers[step],
            question_id: !!currentQuestion ? currentQuestion.id : undefined,
            iteration: currentProject?.iteration || 1,
            answer_content: v.toString()
          };

          setPollAnswers(new_poll_answers);
        }}
        sx={{width: '100%', "input": {padding: 1}}}
      />}
    </>}
    {currentQuestion.question_type === 'single' && <>
      {(currentQuestion.extra_data?.layout || 'normal') === 'normal' && <SlideLayoutSingleNormal
        currentQuestion={currentQuestion}
        pollAnswers={pollAnswers}
        setPollAnswers={setPollAnswers}
        step={step}
        userProfile={userProfile}
      />}
      {(currentQuestion.extra_data?.layout || 'normal') === 'election_form' && <SlideLayoutSingleElectionForm
        currentQuestion={currentQuestion}
        pollAnswers={pollAnswers}
        setPollAnswers={setPollAnswers}
        step={step}
        userProfile={userProfile}
      />}
    </>}
    {currentQuestion.question_type === 'multi' && <>
      {(currentQuestion.extra_data?.layout || 'normal') === 'normal' && <SlideLayoutMultiNormal
        currentQuestion={currentQuestion}
        pollAnswers={pollAnswers}
        setPollAnswers={setPollAnswers}
        step={step}
        userProfile={userProfile}
      />}
      {(currentQuestion.extra_data?.layout || 'normal') === 'election_form' && <SlideLayoutMultiElectionForm
        currentQuestion={currentQuestion}
        pollAnswers={pollAnswers}
        setPollAnswers={setPollAnswers}
        step={step}
        userProfile={userProfile}
      />}
    </>}
    {currentQuestion.question_type === 'nps' && <>
      {(currentQuestion.extra_data?.layout || 'normal') === 'normal' && <></>}
      {(currentQuestion.extra_data?.layout || 'normal') === 'nps' && <SlideLayoutNpsNps
        currentQuestion={currentQuestion}
        pollAnswers={pollAnswers}
        setPollAnswers={setPollAnswers}
        step={step}
        userProfile={userProfile}
      />}
    </>}
    {currentQuestion.question_type === 'none' && <></>}
  </Grid>);
}
