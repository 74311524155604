import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { check_subdomain } from '../lib/server_helper';
import { loginAtom } from '../lib/auth';
import { fetchPage } from '../lib/page';
import { currentProjectAtom } from '../lib/project';

import {
  Box
} from '@mui/material';

import {
  Typography
} from '../components/interface';
import { Helmet } from 'react-helmet';

import { Page } from '../models/Page';

type LandingProps = {};

export const Landing: FunctionComponent<LandingProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);

  // const [currentProject, setCurrentProject] = useState<Project|null>(null);
  const [currentPage, setCurrentPage] = useState<Page|null>(null);

  let subdomain = check_subdomain();
  
  useEffect(() => {
    fetchPage('index', (ob) => {setCurrentPage(ob);}, login);
  }, [currentProject]);

  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  return (<>
    {!!currentPage && !!currentPage.page_title && <Helmet>
      <title>{currentPage?.page_title[lang] || currentPage?.page_title[fb_lang]}</title>
    </Helmet>}
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {!!currentPage && subdomain !== 'admin' && !!currentPage.body && <>
        <Typography html sx={{
          ...(currentProject?.id === 12 ? {
            "& .se-component.se-image-container": {
              display: 'inline-block'
            }
          } : {})
        }}>{currentPage.body[lang] || currentPage.body[fb_lang]}</Typography>
      </>}
    </Box>
  </>);
}
