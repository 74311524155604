import server from './server';
import { Project } from '../models/Project';

export function check_subdomain() {
  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = "";
  if (parts.length >= 3) {
    subdomain = parts[0];
  }
  if (window.location.host.indexOf('localhost') > -1) subdomain = 'admin';
  // if (window.location.host.indexOf('localhost') > -1) subdomain = 'pnlmtrcs12';

  return subdomain;
}

export function fetch_all<Type>(
  url: string,
  params: unknown,
  callback: (z:Type[], y:ApiMultiResult<Type>) => void,
  error_callback: (z:string) => void,
  login?: {auth_token: string, email: string},
  project?: Project
) {
  let h:{[z:string]: any} = {};
  if (!!login) {
    h = {
      "Auth-Token": login.auth_token,
      "Auth-Email": login.email
    };
  }
  if (!!project) {
    h["Project-Token"] = project.project_token;
  }

  return server.post<ApiMultiResult<Type>>(url, params, {
    headers: h
  }).then((_response) => {
    var _data = _response.data;
    if (_data.success) {
      let r = _data.data;
      if (!!r && typeof(r) === 'object' && 'result' in r) r = r.result;
      if (callback !== undefined && typeof(r) === 'object') callback(r, _data);
    } else {
      if (error_callback !== undefined && typeof(_data.data) === 'string') error_callback(_data.data);
    }
  }).catch(_error => {
    if (error_callback !== undefined) error_callback(!!_error.response ? _error.response.data : {});
  });
}

export function fetch_one<Type>(
  url: string,
  params: unknown,
  callback: (z:Type, y:ApiSingleResult<Type>) => void,
  error_callback: (z:string, y:any) => void,
  login?: {auth_token: string, email: string},
  project?: Project
) {
  let h:{[z:string]: any} = {};
  if (!!login) {
    h = {
      "Auth-Token": login.auth_token,
      "Auth-Email": login.email
    };
  }
  if (!!project) {
    h["Project-Token"] = project.project_token;
  }

  return server.post<ApiSingleResult<Type>>(url, params, {
    headers: h
  }).then((_response) => {
    var _data = _response.data;
    if (_data.success) {
      let r = _data.data;
      if (!!r && typeof(r) === 'object' && 'result' in r) r = r.result;
      if (callback !== undefined && typeof(r) === 'object') callback(r, _data);
      if (callback !== undefined && typeof(r) === 'boolean') callback(r, _data);
    } else {
      if (error_callback !== undefined) error_callback(_data.data as string, {});
    }
  }).catch(_error => {
    if (error_callback !== undefined) error_callback(!!_error.response ? _error.response.data : {}, _error);
  });
}

export type ApiResult = {
  data: unknown;
  server_timestamp: string;
  success: boolean;
}

export type ApiMultiResult<Type> = ApiResult & {
  data: Type[] | string | {result : Type[]; count: number;};
}

export type ApiSingleResult<Type> = ApiResult & {
  data: Type | string | {result : Type; count: number;};
}