import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  LanguageFab,
  StyledButton,
  StyledEditor,
  StyledIconButton,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../components/interface';

import { AnswerOption } from '../../models/AnswerOption';
import { Question } from '../../models/Question';

// const { DateTime } = require("luxon");

type AvailableLang = 'nl' | 'fr' | 'en';
type AdminQuestionDetailProps = {};

export const AdminQuestionDetail: FunctionComponent<AdminQuestionDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [questionDetail, setQuestionDetail] = useState<Question|null>(null);
  const [answerOptions, setAnswerOptions] = useState<AnswerOption[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | undefined>(params.project_id);
  const [questionId, setQuestionId] = useState<string | undefined>(params.id);

  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();

  const [activeLocale, setActiveLocale] = useState<AvailableLang>('en');

  const [movingMode, setMovingMode] = useState<boolean>(false);

  const [previewLoading, setPreviewLoading] = useState<boolean>(true);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'body', 'helper', 'nps_unlikely', 'nps_likely', 'extra_data', 'pos', 'get_item', 'question_type', 'is_extended_profile', 'is_optional', 'show_outro_type'
  ];

  const saveSetting = () => {
    setSaveLoader(true);

    let formData = new FormData();
    formData.append('object', 'question');
    formData.append('handler_id', (login?.id || "").toString());
    fields.map(field => {
      formData.append('fields[]', field);
    });
    if (!!projectId) formData.append('ob[project_id]', projectId.toString());
    if (!!questionId) formData.append('ob[id]', (questionId || '').toString());
    if (!!questionId) formData.append('id', (questionId || '').toString());
    // questionDetail
    formData.append('ob[pos]', questionDetail?.pos?.toString() || '1');
    formData.append('ob[is_optional]', !!questionDetail?.is_optional ? '1' : '0');
    formData.append('ob[is_extended_profile]', !!questionDetail?.is_extended_profile ? '1' : '0');
    formData.append('ob[question_type]', questionDetail?.question_type || 'open');
    formData.append('ob[show_outro_type]', questionDetail?.show_outro_type || 'no');
    formData.append('ob[extra_data][alignment]', questionDetail?.extra_data?.alignment || 'left');
    formData.append('ob[extra_data][layout]', questionDetail?.extra_data?.layout || 'normal');
    formData.append('ob[extra_data][min]', questionDetail?.extra_data?.min?.toString() || '1');
    formData.append('ob[extra_data][max]', questionDetail?.extra_data?.max?.toString() || '10');
    formData.append('ob[extra_data][open_type]', questionDetail?.extra_data?.open_type?.toString() || '10');
    formData.append('ob[extra_data][show_faces]', !!questionDetail?.extra_data?.show_faces ? '1' : '0');
    for (const key in (questionDetail?.body || {})) {
      formData.append(`ob[body][${key}]`, ((questionDetail?.body || {})[key] || ''));
    };
    for (const key in (questionDetail?.helper || {})) {
      formData.append(`ob[helper][${key}]`, ((questionDetail?.helper || {})[key] || ''));
    };
    for (const key in (questionDetail?.nps_unlikely || {})) {
      formData.append(`ob[nps_unlikely][${key}]`, ((questionDetail?.nps_unlikely || {})[key] || ''));
    };
    for (const key in (questionDetail?.nps_likely || {})) {
      formData.append(`ob[nps_likely][${key}]`, ((questionDetail?.nps_likely || {})[key] || ''));
    };
    if (!!item) formData.append(`ob[item]`, item);
    if (!!itemUrl) formData.append(`ob[set_item_url]`, itemUrl);

    answerOptions.map((answer_option, i) => {
      if (!!answer_option.id) formData.append(`answer_options[${i}][id]`, answer_option.id.toString());
      formData.append(`answer_options[${i}][pos]`, i.toString());
      for (const key in (answer_option?.body || {})) {
        formData.append(`answer_options[${i}][body][${key}]`, ((answer_option?.body || {})[key] || ''));
      };
    });

    fetch_one<Question>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      setQuestionId(ob.id?.toString());
      setProjectId(ob.project_id?.toString());
      openObject(ob);
      setSaveLoader(false);
      setDividendSavedSnackOpen(true);
      navigate(`/questions/${projectId}`);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchAnswerOptions = () => {
    fetch_all<AnswerOption>('/nl/v3/objects/fetch_all', {
      object: 'answer_option',
      fields: ['id', 'created_at', 'updated_at', 'body', 'extra_data', 'helper', 'pos', 'question|id'],
      per_page: 1000,
      page: 0,
      order: 'pos ASC',
      filter: {
        advanced: {
          question_id: questionId
        }
      }
    }, (obs, complete_data) => {
      setAnswerOptions(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<Question>('/nl/v3/objects/fetch_all', {
      object: 'question',
      fields: fields,
      id: questionId
    }, (ob, complete_data) => {
      openObject(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    fetchCalculation();
    if (!!questionId) fetchAnswerOptions();
  }, []);

  const openObject = (ob: Question) => {
    setQuestionDetail(ob);

    setPreviewLoading(false);
  }

  return (
    <Page sx={{}} title={`Question Detail (${activeLocale})`}>
      <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {setActiveLocale(lc);}}
      />
      <Grid container spacing="8">

        <Grid item container xs={6} spacing="4">
          <StyledEditor
            label="Question content"
            value={questionDetail?.body || {en: ''}}
            activeLocale={activeLocale}
            onChange={(v) => {
              if (!!questionDetail) setQuestionDetail({
                ...questionDetail,
                body: v
              });
            }}
          />
          <Grid item xs={12}>
            <StyledTextFieldTransl
              label="Question helper"
              activeLocale={activeLocale}
              value={questionDetail?.helper || {en: ''}}
              id="helper"
              onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                ...questionDetail,
                helper: v});
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
          {/* imagepicker ? */}
          <Grid item xs={12}>
            <StyledTextField
              label="Position"
              value={questionDetail?.pos?.toString() || '1'}
              id="pos"
              onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                ...questionDetail,
                pos: parseInt(v, 10)});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <StyledSelect
              label="Question type"
              value={questionDetail?.question_type || 'open'}
              id='q_type'
              onChange={(v) => {
                if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  question_type: v
                });
              }}
              list={[
                {id: 'open', name: t("question_types.open")},
                {id: 'multi', name: t("question_types.multi")},
                {id: 'yesno', name: t("question_types.yesno")},
                {id: 'single', name: t("question_types.single")},
                {id: 'location', name: 'Location/map (unsupported)'},
                {id: 'closed', name: t("question_types.none")},
                {id: 'nps', name: t("question_types.nps")}
              ]}
            />
          </Grid>
          {((questionDetail?.question_type || 'open') === 'open') && <>
            <Grid item xs={12}>
              <StyledSelect
                label="Field type"
                value={questionDetail?.extra_data?.open_type || 'short_text'}
                id='open_type'
                onChange={(v) => {
                  if (!!questionDetail) setQuestionDetail({
                    ...questionDetail,
                    extra_data: {
                      ...(questionDetail?.extra_data || {}),
                      open_type: v
                    }
                  });
                }}
                list={[
                  {id: 'short_text', name: t("open_question.field_types.short_text")},
                  {id: 'long_text', name: t("open_question.field_types.long_text")},
                  {id: 'date', name: t("open_question.field_types.date")},
                ]}
              />
            </Grid>
          </>}
          {((questionDetail?.question_type || 'open') === 'multi' || (questionDetail?.question_type || 'open') === 'single') && <>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline', fontStyle: 'italic', fontSize: '0.9rem'}}>{t("questions.answer_options.title")}</Typography>
            </Grid>
            <Grid item container xs={12}>
              {answerOptions.map((answer_option, i) => <>
                <Grid item xs={1}>
                  <Typography sx={{marginTop: '25%'}}>{answer_option.id}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <StyledTextFieldTransl
                    label={`${t("questions.answer_options.answer_option_x_body")} ${i + 1}`}
                    activeLocale={activeLocale}
                    value={answer_option?.body || {en: ''}}
                    id="helper"
                    onChange={(v) => {
                      let new_aos: AnswerOption[] = [];
                      answerOptions.map((ao, ii) => {
                        let new_ao = {...ao};
                        if (i === ii) {
                          new_ao.body = v;
                          new_aos.push(new_ao);
                        } else {
                          new_aos.push(new_ao);
                        }
                      });
                      setAnswerOptions(new_aos);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <StyledIconButton color="primary" sx={{marginTop: 0.5}} onClick={(e) => {
                    let new_aos: AnswerOption[] = [];
                    answerOptions.map((ao, ii) => {
                      let new_ao = {...ao};
                      if (i === ii) {
                        
                      } else {
                        new_aos.push(new_ao);
                      }
                    });
                    setAnswerOptions(new_aos);
                  }}>
                    <DeleteForeverIcon />
                  </StyledIconButton>
                </Grid>
              </>)}
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                label={t("questions.answer_options.add_more_cta")}
                id='add_answer_option'
                contained
                onClick={(v) => {
                  setAnswerOptions([...answerOptions, {}]);
                }}
                sx={{marginLeft: 1}}
              />
            </Grid>
          </>}
          {(questionDetail?.question_type || 'open') === 'nps' && <>
            <Grid item xs={12}>
              <StyledTextField
                label="Minimum (inclusive)"
                value={questionDetail?.extra_data?.min?.toString() || '1'}
                id="nps_min"
                onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  extra_data: {
                    ...(questionDetail?.extra_data || {}),
                    min: parseInt(v, 10)
                  }
                });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Maximum (inclusive)"
                value={questionDetail?.extra_data?.max?.toString() || '10'}
                id="nps_max"
                onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  extra_data: {
                    ...(questionDetail?.extra_data || {}),
                    max: parseInt(v, 10)
                  }
                });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextFieldTransl
                label="Unlikely term (defaults to 'unlikely' when empty)"
                activeLocale={activeLocale}
                value={questionDetail?.nps_unlikely || {en: ''}}
                id="nps_unlikely"
                onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  nps_unlikely: v});
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextFieldTransl
                label="Likely term (defaults to 'likely' when empty)"
                activeLocale={activeLocale}
                value={questionDetail?.nps_likely || {en: ''}}
                id="nps_unlikely"
                onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  nps_likely: v});
                }}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <StyledSwitch
                label="NPS-faces"
                offLabel="Hide faces"
                onLabel="Show faces"
                value={!!questionDetail?.extra_data?.show_faces}
                onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  extra_data: {
                    ...(questionDetail?.extra_data || {}),
                    show_faces: !!v
                  }
                });
                }}
              />
            </Grid> */}
          </>}
        </Grid>

        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Divider sx={{marginTop: 1, marginBottom: 2, width: '100%'}} />

          <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
            <Grid item xs={12}>
              <StyledSwitch
                label="Is optional"
                offLabel="Required"
                onLabel="Optional"
                value={!!questionDetail?.is_optional}
                onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  is_optional: !!v
                });}}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSwitch
                label="Is extended profile (ask once)"
                offLabel="Ask every time"
                onLabel="Ask once"
                value={!!questionDetail?.is_extended_profile}
                onChange={(v) => {if (!!questionDetail) setQuestionDetail({
                  ...questionDetail,
                  is_extended_profile: !!v
                });}}
              />
            </Grid>
          </Grid>

          <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
            <Grid item xs={12}>
              <StyledSelect
                label="Question alignment"
                value={questionDetail?.extra_data?.alignment || 'left'}
                id='q_align'
                onChange={(v) => {
                  if (!!questionDetail) setQuestionDetail({
                    ...questionDetail,
                    extra_data: {
                      ...(questionDetail.extra_data || {}),
                      alignment: v
                    }
                  });
                }}
                list={[
                  {id: 'left', name: t("question_alignments.left")},
                  {id: 'center', name: t("question_alignments.center")},
                  {id: 'right', name: t("question_alignments.right")}
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSelect
                label="Question layout"
                value={questionDetail?.extra_data?.layout || 'normal'}
                id='q_layout'
                onChange={(v) => {
                  if (!!questionDetail) setQuestionDetail({
                    ...questionDetail,
                    extra_data: {
                      ...(questionDetail.extra_data || {}),
                      layout: v
                    }
                  });
                }}
                list={[
                  {id: 'normal', name: t("question_layouts.normal")},
                  ...((questionDetail?.question_type || 'open') === 'single' || (questionDetail?.question_type || 'open') === 'multi' ? [
                    {id: 'election_form', name: t("question_layouts.election_form")},
                  ] : []),
                  ...((questionDetail?.question_type || 'open') === 'nps' ? [
                    {id: 'nps', name: t("question_layouts.nps")},
                  ] : [])
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSelect
                label="Show results on outro"
                value={questionDetail?.show_outro_type || 'no'}
                id='show_outro_type'
                onChange={(v) => {
                  if (!!questionDetail) setQuestionDetail({
                    ...questionDetail,
                    show_outro_type: v
                  });
                }}
                list={[
                  {id: 'no', name: t("show_outro_types.no")},
                  ...((questionDetail?.question_type || 'open') === 'yesno' ? [
                    {id: 'divstabar', name: t("show_outro_types.divstabar")},
                  ] : []),
                  ...((questionDetail?.question_type || 'open') !== 'yesno' ? [
                    {id: 'pie', name: t("show_outro_types.pie")},
                  ] : [])
                ]}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!movingMode && <>
            {!!loader && <CircularProgress />}
            {/* {!loader && <StyledButton
              label="Start manage mode"
              id='start_mm'
              // contained
              onClick={(v) => {
                setMovingMode(true);
              }}
              sx={{marginLeft: 1}}
            />} */}
            {!loader && <StyledButton
              label={t("public.buttons.cancel")}
              id='cancel'
              // contained
              onClick={(v) => {
                navigate(`/questions/${projectId}`);
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label={t("public.buttons.save")}
              id='save'
              contained
              onClick={(v) => {
                saveSetting();
              }}
              sx={{marginLeft: 1}}
            />}
          </>}
          {!!movingMode && <>
            <StyledButton
              label="Stop manage mode"
              id='cancel_mm'
              // contained
              onClick={(v) => {
                setMovingMode(false);
              }}
              sx={{marginLeft: 1}}
            />
          </>}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
